* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

/* NavbarComp.css */
.navbar {
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.navbar-scrolled {
  background-color: rgba(
    242,
    240,
    240,
    0.7
  ); /* Adjust opacity and color as needed */
  height: 60px;
}

/* @font-face {
  font-family: GS-extrabold;
} */
@font-face {
  font-family: GS-bold;
  src: url("../src/assets/fonts/GeneralSans/GeneralSans-Bold.otf");
}
@font-face {
  font-family: GS-semibold;
  src: url("../src/assets/fonts/GeneralSans/GeneralSans-Semibold.otf");
}
@font-face {
  font-family: GS-medium;
  src: url("../src/assets/fonts/GeneralSans/GeneralSans-Medium.otf");
}
@font-face {
  font-family: GS-regular;
  src: url("../src/assets/fonts/GeneralSans/GeneralSans-Regular.otf");
}
@font-face {
  font-family: GS-light;
  src: url("../src/assets/fonts/GeneralSans/GeneralSans-Light.otf");
}

h1,
h2 {
  font-family: GS-bold !important;
}
h3,
h4 {
  font-family: GS-semibold !important;
}
h5,
h6 {
  font-family: GS-medium !important;
}
p {
  font-family: GS-regular !important;
}
small,
a {
  font-family: GS-light !important;
  text-decoration: none !important;
}
/* Custom CSS for additional styling */

.text-wrapper {
  max-width: 300px; /* Set this value according to your design */
  margin: 0 auto;
  text-align: left;
}

.textwrapper {
  max-width: 300px; /* Set this value according to your design */
  margin: 0 auto;
  text-align: center;
}
.btn-black {
  background-color: #0b0a0a !important;
  color: #fff !important;
  border-radius: 30px !important;
  font-size: 1.2rem !important;
  font-family: GS-medium;
}
.btn-black-outline {
  border: 1.5px solid #0b0a0a !important;
  color: #0b0a0a !important;
  border-radius: 30px !important;
  font-size: 1.4rem !important;
  font-family: GS-medium;
  padding: 10px 20px !important;
}

.btn-green {
  background-color: #33b469 !important;
  color: #fff !important;
  border-radius: 30px !important;
  font-size: 1.2rem !important;
  font-family: GS-medium;
  border: 1px #33b469 !important;
}
.btn-grey {
  background-color: #d4ddd8 !important;
   color:black !important;
  border-radius: 8px !important;
  font-size: 1.2rem !important;
  font-family: GS-medium;
  padding: 30px !important;
  width: 100% !important;
}
.bg-green {
  background-color: #33b469 !important;
}
.btn-green-mobile {
  background-color: #33b469 !important;
  color: #fff !important;
  border-radius: 8px !important;
  font-size: 1.2rem !important;
  font-family: GS-medium;
  padding: 30px !important;
  width: 100% !important;
}
.btn-white-outline {
  border: 1.5px solid #fff !important;
  color: #fff !important;
  border-radius: 30px !important;
  font-size: 1.4rem !important;
  font-family: GS-medium;
  padding: 10px 20px !important;
}
.hero-area {
  position: relative;
  background-size: cover;
  background-blend-mode: overlay;
}
.fix {
  background-color: #fff !important;
}
.background {
  margin-top: -30px;
  position: relative;
  width: 100% !important;
}
.hero-image-wrapper {
  position: sticky;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -19%;
  width: 100% !important;
}
.canvass {
  height: 400px !important;
}
.hero-image {
  max-width: 100%;
  max-height: 100%;
  transition: transform 0.3s ease-out;
}

.hero-area h1 {
  font-size: 4rem;
  font-family: GS-medium !important;
  font-weight: 600;
  color: #0a0a0a !important;
}
.hero-area h5 {
  font-size: 1.5rem;
  color: #0a0a0a !important;
}

.code-int-area h2 {
  font-size: 2.8rem !important;
  font-family: GS-medium !important;
}
.code-int-area p {
  font-size: 1.2rem !important;
  color: #a3a3a3;
}
.about {
  font-size: 40px;
  line-height: 48px;
  text-align: left;
  color: #0b0a0a;
}
.code-about-area p {
  font-family: GS-medium !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #353535 !important;
  width: 430px;
}

.footer-area {
  color: #fff;
  background-color: #000;
}
.footer-link a {
  color: #fff !important;
  text-decoration: none;
}
.accordion-item {
  cursor: pointer;
}

.accordion-header {
  padding: 1rem;

  transition: background-color 0.3s;
}

.accordion-header:hover {
  background-color: #e2e2e2;
}

.accordion-body {
  padding: 0 1rem !important;
  border-top: 1px solid #ddd;
  background-color: #fff;
}
.twitter {
  border: 2px solid #ffffff;
  border-radius: 50%;
}
.bar {
  --bs-navbar-brand {
    margin: "8rem";
  }
}
.bbb {
  display: flex;
  justify-content: left !important;

  width: 100%;
}

.bbb p {
  text-align: left;
  width: 280px;
  justify-content: left;
}
.border-top-custom {
  border-top: 1px solid #2a2a2a;
}
.link {
  text-decoration: none !important;
  color: #ffffff;
}
@media screen and (max-width: 450px) {
  .content h1 {
    font-size: 50px;
  }
  .navbarcomp {
    width: 100% !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    display: flex;
  }
  .sidercon {
    width: 100% !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .uniqueid {
    padding: 10px;
  }
  .copy a,
  p {
    font-size: 13px;
  }
  .copy {
    padding: 0;
    width: 100%;
    justify-content: space-between !important;
  }
  .code-about-area p {
    width: 350px;
  }
  .code-about-area {
    padding: 0 40px;
  }
}

/* Add this to your CSS file */
.nav-item-spacing {
  margin-right: 15px; /* Adjust the value as needed */
}

.navlist li a {
  font-family: GS-medium !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 2px !important;
  color: #353535 !important;
}
.form {
  font-family: GS-regular !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  border-radius: 16px !important;
  padding: 16px 10px !important;
}

.atag {
  font-family: GS-medium !important;
  cursor: pointer !important;
}
.aboutp {
  background-color: #0b0a0a;
}
.aboutp h2 {
  font-family: Epilogue;
  font-size: 56px;
  font-weight: 600;
  line-height: 62px;
  text-align: center;
}
.aboutp p {
  font-family: GS-regular !important;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: #a3a3a3;
}
.contact-header {
  font-family: "Epilogue" !important;
  font-size: 56px;
  font-weight: 600;
  line-height: 62px;
  color: #0b0a0a;
}

.contact-paragraph {
  font-family: "GS-medium" !important;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  color: #0b0a0a;
  max-width: 500px;
  margin: 0 auto;
}

/* Custom CSS */
.img-size {
  width: 70px;
}

.dp li {
  padding: 20px;
  font-family: GS-medium !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 2px !important;
  color: #353535 !important;
}
.dp li a {
  color: none !important;
}
.dropdown-item a:active {
  color: #000 !important;
  background: #33b469;
}
.navlist li button {
  font-family: GS-medium !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 2px !important;
  color: #353535 !important;
}
.dropdown-menu .dropdown-item:hover {
  background-color: #ffffff !important; 
}
.card-text h3 {
  font-size: 35px;
}
.container {
  z-index: 1000;
}
.hero-area {
  position: relative;
  height: 200vh;
  margin-top: 150px;
  width: 100% !important;
}

.hero-area .container {
  position: relative;
  z-index: 1; 
}
.hero-img {
  height: 100%;
}

.hero-img.show {
  opacity: 1;
  transform: translateY(0);
}
.cavas {
  opacity: 0.9;
}
.card-text p {
  font-size: 18px;
}
.card-text {
  padding: 30px 30px 0 30px;
  border-radius: 24px !important;
}


.section3 {
  background: #f4f4f4;
  margin-top: -250px !important;
}

.card-2 {
  max-width: 490px !important;
}
.contact {
  margin-top: 100px;
}

 .roww {
    align-items: center !important;
    justify-content: center !important;


  }


@media (min-width: 576px) {
  .img-size {
    width: 75px;
  }
}

@media (min-width: 768px) {
  .img-size {
    width: 100px;
  }
}



@media (min-width: 992px) {
  .img-size {
    width: 150px;
  }
    .section3{
    margin-top: -300px !important;
  }
}

@media (min-width: 1200px) {
  .img-size {
    width: 200px;
  }
}
@media (max-width: 550px) {
  .canvass {
    height: 300px !important;
    width: 100% !important;

  }
  .section3{
    margin-top: -650px !important;
  }
  .hero-image{
    margin-top: -500px !important;
    z-index: 1000;

  }

  .hero-image-wrapper{
    /* background: blueviolet !important; */
  }
  .hero-area {
    position: relative;
    height: 180vh !important;
    margin-top: 150px;
    width: 100% !important;
  }
  .roww {
    align-items: center !important;
    justify-content: center !important;
  }
}
@media (max-width: 400px) {

  .hero-area {
    position: relative;
    /* height: 180vh !important;
    margin-top: 150px; */
    width: 100% !important;
  }

    .hero-image{
    margin-top: -400px !important;
    z-index: 1000;

  }
  .section3{
    margin-top: -450px !important;
  }

}

@media (max-width: 380px) {


    .hero-image{
    margin-top: -300px !important;
    z-index: 10000 !important;

  }

  .section3{
    margin-top: -200px !important;
  }

}

.navbar-collapse.show.navbar-pink {

  background-color:  whitesmoke !important;
}
